import React from "react";
import { faSquarePlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box, Fade, Menu, MenuItem, Typography,
} from "@mui/material";
import { Trans } from "@lingui/macro";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import moment from "moment";

const calendarOptions = () => [
  {
    name: <Trans>Google Calendar</Trans>,
    icon: faGoogle,
    href: ({ title, startDate, endDate }) => `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${formatDateGoogle(startDate)}/${formatDateGoogle(endDate)}&amp;sprop=&amp;sprop=name:`,
    // `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDate}/${endDate}&details=${details}&location=${venue}&amp;sprop=&amp;sprop=name:`
  },
  // {
  //   name: <Trans>Yahoo! Calendar</Trans>,
  //   icon: faYahoo,
  //   href: ({ title, startDate, endDate }) =>
  //     `http://calendar.yahoo.com/?v=60&view=m&type=20&title=${title}&st=${startDate}&et=${endDate}`,
  // },
  // {
  //   name: <Trans>iCal Calendar</Trans>,
  //   icon: faCalendar,
  //   href: ({ title, startDate, endDate }) =>
  //     `data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:http://carlsednaoui.github.io/add-to-calendar-buttons/generator/generator.html%0ADTSTART:${moment(startDate).toISOString()}%0ADTEND:${moment(endDate).toISOString()}%0ASUMMARY:${title}%0AEND:VEVENT%0AEND:VCALENDAR`,
  //   // `data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:http://carlsednaoui.github.io/add-to-calendar-buttons/generator/generator.html%0ADTSTART:${startDate}%0ADTEND:${endDate}%0ASUMMARY:${title}%0ADESCRIPTION:${details}0ALOCATION:${venue}%0AEND:VEVENT%0AEND:VCALENDAR`,
  // },
  {
    name: <Trans>Outlook Calendar</Trans>,
    icon: faMicrosoft,
    href: ({ title, startDate, endDate }) => `https://outlook.office.com/calendar/0/deeplink/compose?path=path=/calendar/action/compose&rru=addevent&subject=${title}&body=Event description text&startdt=${startDate}&enddt=${endDate}`,
    // `https://outlook.office.com/calendar/0/deeplink/compose?subject=${title}&startdt=${startDate}&enddt=${endDate}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent`,
  },
];

const formatDateGoogle = (date) => `${date?.replace(".000Z", `.${new Date(date).getTimezoneOffset() / (-60)}00Z`).replaceAll("-", "").replaceAll(":", "")}`;

function AddButtonCalendar({ title, startDate, endDate }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const _endDate = endDate || moment(startDate).add(2, "hours").toISOString();
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  return (
    <>
      <FontAwesomeIcon
        style={{ cursor: "pointer" }}
        icon={faSquarePlus}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {calendarOptions().map(({ name, icon, href }) => (
          <MenuItem>
            <Box
              display="flex"
              alignItems="center"
              onClick={() => window.open(href({ title, startDate, endDate: _endDate }), "_blank")}
            >
              <FontAwesomeIcon icon={icon} />
              <Typography variant="small" ml={1}>
                {name}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default AddButtonCalendar;
