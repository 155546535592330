import React from "react";
import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocalization } from "gatsby-theme-i18n";
import _ from "lodash";
import moment from "moment";
import Container from "../../../components/Container";
import NewLayout from "../../../components/NewLayout";
import InterestedItems from "../../../components/InterestedItems";
import financialCalendars from "../../../cms/financialCalendars/financialCalendars";
import { headers } from "../../../utils/navbar";
import AddButtonCalendar from "../../../components/AddButtonCalendar";
import { transformArticleDate } from "../../../utils/screen";

const tableHeaders = () => ["Date", "Event"];

function FinancialCalendarPage() {
  const format = "YYYY-MM-DD";
  const { locale, config } = useLocalization();
  const hrefLang = config.find((conf) => conf.code === locale)?.hrefLang;
  const currentDate = moment(moment(), format);
  const [pastEvents, setPastEvents] = React.useState([]);
  const [newEvents, setNewEvents] = React.useState([]);

  const interestedItems = [
    { ..._.get(headers(), "investors.elements.investors_about") },
    { ..._.get(headers(), "investors.elements.share_information") },
    { ..._.get(headers(), "investors.elements.financial_information_reports.elements.ipo") },
  ];

  React.useEffect(() => {
    const currentEvents = financialCalendars[locale];
    if (!_.isEmpty(currentEvents)) {
      setNewEvents(currentEvents.filter(({ attributes }) => moment(attributes.publish_date, format).diff(currentDate) >= 0));
      setPastEvents(currentEvents.filter(({ attributes }) => moment(attributes.publish_date, format).diff(currentDate) < 0));
    }
  }, []);

  const renderTable = (title, _events, addCalendar = false) => {
    const headers = addCalendar ? [...tableHeaders(), "Add to Calendar"] : tableHeaders();
    return (
      <Box>
        {_.isEmpty(_events) ? (
          <Box width="100%" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h3">{title}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell align={index === headers.length - 1 && addCalendar ? "center" : "start"}>
                      <Typography variant="h5">{header}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {_events.map(({ attributes: { publish_date, event } }) => (
                  <TableRow>
                    <TableCell><Typography variant="small">{transformArticleDate(publish_date, hrefLang)}</Typography></TableCell>
                    <TableCell><Typography variant="small">{event}</Typography></TableCell>
                    {addCalendar && <TableCell align="center"><AddButtonCalendar title={event} startDate={publish_date} /></TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </Box>
    );
  };

  return (
    <NewLayout
      byDefault
      apps="investors.financial_information_reports.financial_calendar"
    >
      <Container pb={8}>
        <Stack mt={3} spacing={2} maxWidth="70%">
          <Typography variant="h2">
            Financial Calendar
          </Typography>
          <Typography>
            The financial calendar provides an overview of events in
            connection with the engagement with our stakeholders and the
            publication of financial information for the current and past
            financial years.
          </Typography>
        </Stack>
        <Stack spacing={8} mt={6}>
          <Box>
            {renderTable("Upcoming Events", newEvents, true)}
          </Box>
          <Box>
            {renderTable("Past Events", pastEvents.reverse())}
          </Box>
        </Stack>
        <InterestedItems items={interestedItems} />
      </Container>
    </NewLayout>
  );
}

export default FinancialCalendarPage;
